.bglayer1 {
  background: linear-gradient(
    180.7deg,
    rgba(0, 60, 188, 0.4757) 9.52%,
    rgba(0, 161, 255, 0.2485) 32.15%,
    rgba(15, 146, 234, 0.284) 47.4%,
    rgba(26, 104, 216, 0.1065) 63.8%,
    rgba(1, 30, 74, 0.6177) 89.69%
  );
}

.bglayer0 {
  background: #003cbc;
}
